@use "../variables" as v;

#congrats-container{
  height:100vh;
  background-image: url("../assets/background.png");
  background-size: cover;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: v.$theme-gold;

  #light-container{
    position: absolute;
    top: -80px;
  }

  .home-title{
    display: flex;
    margin-top: 85px;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
  }

  .nft-container{
    width: 236px;
    height: 236px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 100%;
      height: 100%;
    }
  }

  #platform{
    width: 300px;
    height: 115px;
    position: absolute;
    bottom: 156px;

    img{
      width: 100%;
      height: 100%;
    }
  }

  #retrieve-button{
    //position: absolute;
    //bottom: 96px;
    z-index: 4;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    height: 39px;
    background: linear-gradient(90deg, #DF4432 0%, #EE8174 100%);
    box-shadow: 0px 10px 11px rgba(38, 6, 2, 0.47);
    border-radius: 35px;
    color: white;
  }



}